import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Meta from "../components/meta"
import styles from "./index.module.css"
import CloudashBox from "../components/cloudash"

export default props => {
  const posts = props.data.allMarkdownRemark.edges

  return (
    <Layout>
      <SEO />
      <section className={styles.hero}>
        <h2>Hi!</h2>
        <p>My name is Maciej Winnicki, and I build serverless architectures.</p>
      </section>
      <CloudashBox />
      <section className={styles.articles}>
        <h2>Articles</h2>
        <ul className={styles.list}>
          {posts.map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <li key={node.fields.slug} className={styles.article}>
                <Link to={node.fields.slug}>
                  <h2>{title}</h2>
                  <section className={styles.desc}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.excerpt,
                      }}
                    />
                    <Meta
                      includePicture={false}
                      date={node.frontmatter.date}
                      timeToRead={node.timeToRead}
                    />
                  </section>
                </Link>
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
          }
          timeToRead
        }
      }
    }
  }
`
