import React from "react"

import styles from "./cloudash.module.css"

export default () => {
  return (
    <section className={styles.cloudash}>
      <header>
        <a href="https://cloudash.dev">
          <h3>Cloudash - Serverless monitoring. Simplified.</h3>
        </a>
      </header>
      <section>
        <p>
          <a href="https://cloudash.dev">
            Tired of constantly switching between CloudWatch tabs? Cloudash
            provides a laser-focused view of your API Gateway APIs and Lambda
            functions - all in a single window.
          </a>
        </p>

        <p>
          <a href="https://cloudash.dev">
            Try Cloudash - a desktop app for monitoring your AWS API Gateway
            APIs and Lambda functions.
          </a>
        </p>
      </section>
    </section>
  )
}
